.preview{
    display:flex;
    flex-direction: column;
    height: 100%;
  

}

.preview__scroll{
    overflow: auto;
    flex-grow: 1;
    padding-left: 25px;
}

.preview__scroll > h1, h2 ,h3 ,h4 ,h5, h6 {
  text-align: center;
  color:inherit;
}

.preview__scroll ul li{
    list-style: square !important;
    color:inherit;
}
.preview__scroll ol li{
  list-style:decimal !important;
  color:inherit;
}

   p > img {
    display: block;
    margin: auto;
    margin-bottom: 10px;
    
  }
  h2 > img {
    display: block;
    margin: auto;
    
  }


.preview__scroll > p > img{
  width: 500px;
  box-shadow: var(--shadow3);
  border-radius: 10px;
}

@media (max-width:750px) {
  .preview__scroll > p > img{
    width: 200px;
    
  }
}
@media (max-width:390px) {
  .preview__scroll > p > img{
    width: 100px;
  }
}

.preview__scroll> p > a{
  color:inherit;
}

.preview__scroll> p > a:hover{
  color:var(--primary);
}

pre{
    background-color: rgb(148, 148, 231, 0.5);
    border-radius: 5px;
    width: auto;
    overflow: hidden; 
   
}

pre > code{
    background-color:transparent;   
}

code{
    background-color: rgb(148, 148, 231, 0.8);
    border-radius: 3px;
    /* color: var(--white); */
    padding: 2px;
    width: auto; 
    overflow: scroll;  
}

blockquote {
    quotes: "\201C""\201D""\2018""\2019";
  }
  blockquote:before {
    /* color: var(--white); */
    content: open-quote;
    font-size: 1.5em;
    line-height: 0.1em;
    vertical-align: -0.2em;
  }

  blockquote:after {
    /* color: var(--white); */
    content: close-quote;
    font-size: 1.5em;
    line-height: 0.1em;
    vertical-align: -0.2em;
  }
  blockquote p {
    display: inline;
  }
  @media (max-width:430px) {
    .preview__scroll > p > img{
      width: 100px;
      
    }
    .preview__scroll h1 {
      font-size: 24px;
    }
    
    .preview__scroll h2 {
      font-size: 20px;
    }
    
    .preview__scroll h3 {
      font-size: 18px;
    }
    
    .preview__scroll h4 {
      font-size: 16px;
    }
    
    .preview__scroll h5 {
      font-size: 14px;
    }
    
    .preview__scroll h6 {
      font-size: 12px;
    }
    
    .preview__scroll p {
      font-size: 14px;
    }
  
  }