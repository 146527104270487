.titleBarWrap{
    display: flex;
    height: 100%;
    width: auto;
    flex-wrap: wrap; 
}

.titleBar{
    padding: 0.4rem; 
}

.titleBar:hover{
    cursor: pointer;
}

.all{
    /* border:1px solid; */
    width: auto;
    height: auto;
    /* border-radius: 5px; */ 
}

.btnTools {
    position: relative;
    color: var(--primary);
    background-color: transparent;
    border: none; 
}

.btnTools:hover{
    background-color: var(--secondary);
    border-radius: 4px;
}

  .btnTools[aria-label]:focus:after,
  .btnTools[aria-label]:hover:after {
    position: absolute;
    z-index: 100;
    top: -.5em;
    left: 1.2em;
    display: block;
    overflow: hidden;
    width: 10em;
    height: 1.5em;
    border-radius: .2em;
    padding: 0 .7em;
    content: attr(aria-label);
    color: #fff;
    background: #000;
    font-size: 1em;
    line-height: 2em;
    text-align: left;
  }

  .dropdown-menu ul li {
    list-style: none;
    color: currentColor;

   
   
  }

  .dropdown-menu ul{
    text-align: center;
    background-color: var(--secondary);
    padding-left: 20px;
    border-radius: 10px;
    
  }

  .dropdown-menu{
    position: absolute;
    z-index: 1;
    box-shadow: var(--shadow3);
    border-radius: 10px;
    
  }

  .emojiPiker{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  .emoji{
    font-size: 16px;
  }


  .aviso{
    /* padding: 15px;
    background-color: rgb(10, 232, 10);
    position: absolute;
    bottom: 150px;
    left: 100px; */
    position : fixed;
    top : 50%;
    left : 50%;
    transform : translate(-50%, -50%);
    padding : 1rem;
    background : white;
    box-shadow : var(--shadow3);
    z-index : 999;
    border-radius: 10px;
    color:#a29dff
  }

 

