.fichero{
    width: 200px;
    height: 230px;
    position: relative;
    background: #686686;
    border-radius: 2px;
    box-shadow: inset 1px 1px 5px 0 #406079;
    cursor: pointer;
}

.fichero~legend{
    cursor: pointer;
}

.fichero::before{
    content: '';
    position: relative;
    top: -1;
    left:70%;
    float: left;
    width: 0;
    height: 0;
    border: 31px solid #f3f4f6;
    border-bottom-color:#665add ;
    border-left-color:#665add ;
}
.filesList{
    margin: 20px 4rem;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.fichero h2{
    padding-top:4rem;
    padding-bottom: 2rem;
    font-size: 3.5rem;
}
.fichero button:first-of-type{
    color: #d3d0ff;
    position: absolute;
    right: 2px ;
    bottom: 0;
    margin-bottom:5px ;
}
.fichero button:last-of-type{
    color: #d3d0ff;
    position: absolute;
    right: 40px ;
    bottom: 0;
    margin-bottom:5px ;

}