
  /* Nuevo css */
  .nav {
    height: 70px;
    width: 100%;
    background-color: var(--darker) ; 
    position: relative;
  }
  
  .nav > .nav-header {
    display: inline;
  }
  
  .nav > .nav-header > .nav-title  {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 4px 55px;
  }

   .nav-title > .logoName{
    font-size: 22px;
    color: #fff;
    float:left;
    padding: 10px 10px 4px 25px;
  
  }

  .logo{
    float: left;
  }

  .logoName:hover{
    color: var(--primary);
  }
  
  .nav > .nav-btn {
    display: none;
  }
  
  .nav > .nav-links {
    display: inline;
    float: right;
    font-size: 18px;
    z-index: 100000;
  }
  
  /* .nav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
  } */
  
  ul{
    padding: 0;
    margin: 0;
    padding-right: 30px;
  }


  .nav > .nav-links > ul > li {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
  }


  /* .nav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
  } */

  .nav > .nav-links > ul > li:hover {
    color: #b07ff0;
    cursor: pointer;
  }

  .nav-title:hover {
    cursor: pointer;
  }
  
  .nav > #nav-check {
    display: none;
  }

  .btn-mode{
    font-size: 1.5rem;
  }

/* Button Upload Document */
  input.inputNav[type="file"] {
  display: none;
}

.file-custom::-webkit-file-upload-button {
  visibility: hidden;
}
.file-custom::before {
  content: 'Upload Documents';
  display: inline-block;
  /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
  border: 1px solid var(--primary);
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  margin-left: 5px;
  color: var(--primary);
}
.file-custom:hover::before {
  border-color: var(--white);
  color: var(--white);
}
/* .file-custom:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
} */
/* End Button Upload Document */

/* Button Download Document */
input[type="'text/plain'"] {
  display: none;
}

.file-customDownload::-webkit-file-upload-button {
  visibility: hidden;
}
.file-customDownload::before {
  content: 'Download Documents';
  display: inline-block;
  /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
  border: 1px solid  var(--primary);
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  margin-left: 5px;
  color: var(--primary);
}

.file-customDownload:hover::before {
  border-color: var(--white);
  color: var(--white);
}
.file-customDownload:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
/* End Button Upload Document */

  @media (max-width:975px) {
    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
      background-color: var(--dark);
    }
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }
    .nav > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      background-color: var(--dark);
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 50px;
      left: 0px;
    }
    /* .nav > .nav-links > a {
      display: block;
      width: 100%;
    } */

    .nav > .nav-links > ul > li {
      display: block;
      width: 100%;
      margin: 25px;
      border-radius: 10px;
    } 

    .nav > .nav-links > ul > label {
      display: block;
      width: 100%;
      margin: 25px;
    } 


    .nav > .nav-links > ul > li:hover {
      display: block;
      width: 100%;
      background-color: #b07ff0;
      color: #333;
    } 


    .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
    .nav > #nav-check:checked ~ .nav-links {
      height: calc(100vh - 50px);
      overflow-y: auto;
      
    }
  }

  @media (max-width:350px) {
    .logoName{
      display: none;
    }
   

  }


