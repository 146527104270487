@tailwind base;
@tailwind components;
@tailwind utilities;

/*Fonts*/
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Add your custom styles here */

@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

/*Colors*/

:root {
  --dark: #22303c;
  --darker: #192734;
  --darkest: #15202b;
  --contrast: #8899ac;
  --primary: #665add;
  --white: #eeee;
  --secondary: #e9d5ff;
  --italic: #2b2c2c;
  --shadow1: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --shadow2: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --shadow3: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  /* Paleta de indigo personalizado */
  --indigo10: #e2e0ff;
  --indigo20: #c1beff;
  --indigo30: #a29dff;
  --indigo40: #837dff;
  --indigo50: #645cff;
  --indigo60: #504acc;
  --indigo70: #3c3799;
  --indigo80: #282566;
  --indigo90: #141233;

  /* Paleta de Grises */
  --gray100: #f8fafc;
  --gray200: #f1f5f9;
  --gray300: #cbd5e1;
  --gray400: #94a3b8;
  --gray500: #94a3b8;
  --gray600: #64748b;
  --gray700: #334155;
  --gray800: #1e293b;
  --gray900: #0f172a;

  /* Other colors*/
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --red-intense: #ff0000;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;
  --green-light: #34bc48;
  --green-alert: #008000;
}

/* General Styles*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arimo, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font—smoothing: antialiased;
  -webkit-font—smoothing: grayscale;
  /* -moz-osx-font—smoothing: grayscale;  */
  background-color: var(--darkest);
  color: var(--white);
}

/* Estilos para el modo oscuro */
body.dark {
  background-color: var(--darkest);
  color: var(--white);
}

/* Estilos para el modo claro */
body.light {
  background-color: var(--white);
  color: var(--darkest);
}

/*Typography*/
p {
  margin: 0%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0%;
}

li ul {
  padding-left: 20px;
}

h1 {
  font-size: 2.625em;
  line-height: 1.55;
  font-weight: 900;
}
h2 {
  font-size: 2em;
  line-height: 1.58;
  font-weight: 900;
}
h3 {
  font-size: 1.625em;
  line-height: 1.62;
  font-weight: 900;
}
h4 {
  font-size: 1.25em;
  line-height: 1.65;
  font-weight: 900;
}
h5 {
  font-size: 1em;
  line-height: 1.65;
  font-weight: 700;
} 
h6 {
  font-size: 0.813em;
  line-height: 1.77;
  font-weight: 700;
}

/*Anchors*/
a {
  color: white;
}

* > a:hover {
  color: var(--primary);
}

em {
  color: var(--contrast);
}

button:hover {
  cursor: pointer;
}
/*Horizontal line*/
hr {
  border: none;
  height: 1px;
  background-color: var(--dark);
  margin: 0%;
  margin-bottom: 10px;
}

/*Utilities*/
.mbt-2 {
  margin-bottom: 0.5rem;
  color: inherit;
}
.mbt-4 {
  margin-bottom: 1rem;
}

/* ICONOS FALLO FORMULARIO */

.error span {
  color: var(--red-intense);
}

.failure-icon i {
  color: var(--red-intense);
}

.success-icon i {
  color: var(--green-alert);
}

.error span {
  font-size: calc(6px + 1vmin);
  margin-top: 5px;
}
.error {
  color: var(--red-intense);
}

.success-icon,
.failure-icon {
  /* right: center; */
  opacity: 0;
}
svg .eye-open {
  color: var(--red-intense);
}

input::placeholder {
  color: var(--indigo30);
}

input[type="file"]{
  color: var(--indigo50);
}

.option-title{
  color: var(--indigo50);
}

li> ol{
  padding-left: 20px;
}
