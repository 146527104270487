
  .manual {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: var(--secondary);
    padding: 20px;
    width: 600px;
    color: var(--dark);
    height: 900px;
    border-radius: 15px;
    overflow-wrap: break-word;
    box-shadow: var(--shadow3);
  }

  .manual:hover{
    cursor: auto;
  }
  
  .manual h2 {
    margin-top: 0;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  .minimize-button {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

   .titleH {
    text-align: center;
  }

  .headerManual {
    text-align: left;
  }

  .spanManual{
    color: var(--darkest);
  }
  
  @media (max-width:650px) {
    .manual {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        background-color: var(--secondary);
        padding: 20px;
        width:300px;
        color: var(--dark);
        border-radius: 15px;
        overflow-wrap: break-word;
        font-size: smaller;
      }
    }

