.editor__wrap{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.editor{
    width: 100%;
    flex-grow: 1;
    color: inherit;
    margin-bottom: 20px;
    outline: none;
    appearance: none;
    background: none;
    resize: none;
    border: none;
    font-size: calc(1rem); 
}
/* .editor .light{
    width: 100%;
    flex-grow: 1;
    margin-bottom: 20px;
    outline: none;
    appearance: none;
    background: none;
    resize: none;
    border: none;
    font-size: calc(1rem);  
} */

@media (max-width:430px) {

    .editor {
        font-size: 14px;
    }
}