.page-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    height:2.8rem;
    display: flex;
    background-color: var(--primary);
    justify-content: space-evenly;
    margin-left: 60px;
    flex-wrap: wrap;
    font-size: calc(10px + 0.75vmin); 
  }
  .page-footer p {
      margin-bottom: 0;
    }
  
  .footerText{
    padding-top: 10px;
    color: var(--white);
  }
  
  .page-footer .footer-logo,
  .page-footer a {
   color: var(--white);
  }
  
  span{
    margin-right: 25px;
    color: var(--white);
  }
  a{
    text-decoration: none;
 
  }
  .page-footer a:hover{
    color:var(--darkest)
   
  }

  @media (max-width:450px) {
    .page-footer{
      height:3.8rem;
      text-align:left;

    }

  }